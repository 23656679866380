<template>
  <div>
    testing
  </div>
</template>

<script>
import moment from 'moment'

export default {
  mounted() {
    let diff = moment(1605152084227).diff(moment(), 'days')
    console.log(diff <= -3)
  }
}
</script>